import React, { FormEvent, useCallback, useRef, useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VALIDATION_DEFAULT_MESSAGE, VALIDATION_DEFAULT_TEXT, VALIDATION_CHOICE } from "~/utils/validation";
import { colors } from "~/utils/colors";
import { PRIVACY_POLICY_URL, PRIVACY_POLICY_MARKETING_URL } from "~/constant/path";
import { SubmitButton } from "~/atoms/SubmitButton";
import rgba from "polished/lib/color/rgba";
import { getGaClientId } from "~/utils/getGaClientId";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { numbersOfHiredAnnually, ratiosOfRecruitmentAgency, numbersOfEmployees, preferredRecruitmentTypes, currentStatuses } from "~/utils/variables";

type FormValues = {
  lastName: string;
  firstName: string;
  companyName: string;
  email: string;
  phone: string;
  numberOfEmployees: string;
  numberOfHiredAnnually: string;
  ratioOfRecruitmentAgency: string;
  permissionPrivacyPolicy: boolean;
  preferredRecruitmentType: string,
  currentStatus: string,
};

const validationSchema = Yup.object().shape({
  lastName: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  firstName: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  companyName: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  email: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required).email(VALIDATION_DEFAULT_TEXT.email),
  phone: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  numberOfEmployees: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  numberOfHiredAnnually: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  ratioOfRecruitmentAgency: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  // @Note: 同意がTrueじゃなかったらActiveにならないようにする
  permissionPrivacyPolicy: Yup.boolean().oneOf([true], VALIDATION_CHOICE.accepted),
  preferredRecruitmentType: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
  currentStatus: Yup.string().required(VALIDATION_DEFAULT_MESSAGE.required),
});

const initialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  phone: "",
  numberOfEmployees: "",
  numberOfHiredAnnually: "",
  ratioOfRecruitmentAgency: "",
  permissionPrivacyPolicy: false,
  preferredRecruitmentType: "",
  currentStatus: "",
};

export const CaseCollectionForm: React.FC<{ formspreeEndpoint: string | null | undefined; thanksPath: string }> = ({
  formspreeEndpoint,
  thanksPath,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [result, setResult] = useState<{
    status: boolean;
    message: string;
  } | null>(null);
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => handleSubmit(values),
    validationSchema,
  });

  const handleServerResponse = (status, message) => {
    setSubmitting(false);
    setResult({
      status,
      message,
    });

    if (status) {
      formik.resetForm({ values: formik.values });
      // @Note: ページ遷移させるけど入力内容はクリアしておく
      formRef.current?.reset();
      navigate(thanksPath);
    }
  };

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      if (!formspreeEndpoint) return null;
      setSubmitting(true);

      const {
        lastName,
        firstName,
        companyName,
        email,
        phone,
        numberOfEmployees,
        numberOfHiredAnnually,
        ratioOfRecruitmentAgency,
        preferredRecruitmentType,
        currentStatus,
      } = values;

      const formData = new FormData();
      const gaCid = getGaClientId();

      formData.append(`lastName`, lastName);
      formData.append(`firstName`, firstName);
      formData.append(`companyName`, companyName);
      formData.append(`email`, email);
      formData.append(`phone`, phone);
      formData.append(`numberOfEmployees`, numberOfEmployees);
      formData.append(`gaClientId`, gaCid || "");
      formData.append(`formUrl`, location.href);
      formData.append(`numberOfHiredAnnually`, numberOfHiredAnnually);
      formData.append(`ratioOfRecruitmentAgency`, ratioOfRecruitmentAgency);
      formData.append(`preferredRecruitmentType`, preferredRecruitmentType);
      formData.append(`currentStatus`, currentStatus);

      try {
        await axios({
          method: "post",
          url: formspreeEndpoint,
          data: formData,
        });
        handleServerResponse(true, `success`);
        ga("set", "dimension2", gaCid);
      } catch (error) {
        handleServerResponse(false, error.response.data.error);
        throw new Error(error.message);
      }

      return null;
    },
    [submitting, result]
  );

  return (
    <>
      <style jsx>{`
        .form {
          background-color: ${colors.white};
          padding: 20px;
        }
        .formGroup {
          margin-bottom: 20px;
        }
        .formGroupLabel {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .label {
          font-size: 13px;
        }
        .textInput {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          height: 38px;
          width: 100%;
          box-sizing: border-box;
          display: block;
          box-shadow: none;
        }
        .textInput::placeholder {
          color: ${colors.gray2};
        }
        .textInput:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .selectContainer {
          display: block;
          position: relative;
        }
        .selectContainer :global(svg) {
          position: absolute;
          top: 9px;
          right: 8px;
          pointer-events: "none";
        }
        .select {
          font-size: 16px;
          line-height: 1.5;
          border: 1px solid ${colors.gray3};
          background-color: ${colors.gray3};
          border-radius: 4px;
          padding: 6px 8px;
          height: 38px;
          width: 100%;
          display: block;
          box-sizing: border-box;
          box-shadow: none;
          transition: all 0.15s ease-out;
        }
        .select:focus {
          outline: 0;
          border: 1px solid ${colors.orange};
          box-shadow: 0 0 3px 3px ${rgba(colors.orange, 0.1)};
          background-color: ${colors.white};
          transition: all 0.15s ease-out;
        }
        .selectEmpty {
          color: ${colors.gray2};
        }
        .checkListItem {
          position: relative;
          line-height: 1;
          margin-bottom: 5px;
        }
        .checkListItem:last-child {
          margin-bottom: 0;
        }
        .checkListLabel {
          color: ${colors.black};
          font-weight: bold;
          font-size: 13px;
          margin: 0 0 10px 0;
        }
        .checkboxInput {
          box-sizing: border-box;
          padding: 0;
          position: absolute;
          top: 0;
          opacity: 0;
        }
        .checkboxLabel {
          display: inline-flex;
          cursor: pointer;
        }
        .checkboxLabelIcon {
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          display: block;
          border-radius: 4px;
          background-color: ${colors.gray3};
          border: 2px solid ${colors.gray3};
          width: 20px;
          height: 20px;
          margin-right: 10px;
          flex: 0 0 auto;
        }
        .checkboxLabelIcon:hover {
          border-color: ${colors.orange};
          background-color: ${colors.gray};
          transition: background-color 0.2s, border-color 0.2s;
        }
        .checkboxLabelIcon:before {
          content: "";
          display: block;
          position: absolute;
          border-right: 2px solid ${colors.orange};
          border-bottom: 2px solid ${colors.orange};
          border-radius: 1px;
          opacity: 0.2;
          top: 0;
          left: 4px;
          width: 8px;
          height: 12px;
          transform: rotate(45deg);
          transition: opacity 0.15s ease-out;
        }
        .checkboxLabelIcon.checkboxLabelIconChecked {
          border-color: ${colors.orange};
          background-color: ${colors.orange};
        }
        .checkboxLabelIcon.checkboxLabelIconChecked:before {
          border-right: 2px solid ${colors.white};
          border-bottom: 2px solid ${colors.white};
          opacity: 1;
        }
        .checkboxLabelText {
          color: ${colors.black};
          font-size: 15px;
          line-height: 20px;
        }
        .checkboxLabelTextLink {
          color: ${colors.brand};
        }
        .checkListItem {
          position: relative;
          line-height: 1;
          margin-bottom: 5px;
        }
        .checkListItem:last-child {
          margin-bottom: 0;
        }
        .checkListLabel {
          color: ${colors.black};
          font-weight: bold;
          font-size: 13px;
          margin: 0 0 10px 0;
        }
        .checkboxInput {
          box-sizing: border-box;
          padding: 0;
          position: absolute;
          top: 0;
          opacity: 0;
        }
        .checkboxLabel {
          display: inline-flex;
          cursor: pointer;
        }
        .checkboxLabelIcon {
          box-sizing: border-box;
          position: relative;
          cursor: pointer;
          display: block;
          border-radius: 4px;
          background-color: ${colors.gray3};
          border: 2px solid ${colors.gray3};
          width: 20px;
          height: 20px;
          margin-right: 10px;
          flex: 0 0 auto;
        }
        .checkboxLabelIcon:hover {
          border-color: ${colors.orange};
          background-color: ${colors.gray};
          transition: background-color 0.2s, border-color 0.2s;
        }
        .checkboxLabelIcon:before {
          content: "";
          display: block;
          position: absolute;
          border-right: 2px solid ${colors.orange};
          border-bottom: 2px solid ${colors.orange};
          border-radius: 1px;
          opacity: 0.2;
          top: 0;
          left: 4px;
          width: 8px;
          height: 12px;
          transform: rotate(45deg);
          transition: opacity 0.15s ease-out;
        }
        .checkboxLabelIcon.checkboxLabelIconChecked {
          border-color: ${colors.orange};
          background-color: ${colors.orange};
        }
        .checkboxLabelIcon.checkboxLabelIconChecked:before {
          border-right: 2px solid ${colors.white};
          border-bottom: 2px solid ${colors.white};
          opacity: 1;
        }
        .checkboxLabelText {
          color: ${colors.black};
          font-size: 15px;
          line-height: 20px;
        }
        .checkboxLabelTextLink {
          color: ${colors.brand};
        }
        .errorText {
          color: ${colors.red};
          display: block;
          font-size: 13px;
          line-height: 1;
          margin-top: 5px;
        }
        .action {
          display: flex;
          justify-content: center;
        }
        .submitButton {
          background-position: calc(100% - 30px) center;
          background-size: 15px auto;
          background: linear-gradient(
            270deg,
            #ea3375 -73%,
            #ea3572 -69.4%,
            #eb4656 -31.72%,
            #ec5145 1.32%,
            #ec553f 25.96%,
            #ec8317 100%
          );
          border-radius: 50px;
          position: relative;
          display: block;
          text-align: center;
          padding: 15px 20px;
          box-shadow: 0 0 12px ${rgba(colors.black, 0.1)};
          color: ${colors.white};
          font-size: 15px;
          font-weight: bold;
          transition: all 0.15s ease-out;
          max-width: 500px;
          width: 100%;
          opacity: 1;
        }
        .submitButton:hover {
          opacity: 0.8;
        }
        .submitButton:disabled {
          background: none;
          background-color: ${colors.gray3};
          color: ${colors.gray2};
          cursor: default;
          box-shadow: none;
        }
        .submitButton:disabled:hover {
          opacity: 1;
        }
      `}</style>
      <form
        className="form"
        ref={formRef}
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="formGroup">
          <label className="formGroupLabel">
            <input
              type="text"
              className="textInput"
              id="companyName"
              name="companyName"
              value={formik.values.companyName}
              placeholder="会社名"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("companyName", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <span className="errorText">{formik.errors.companyName}</span>
            )}
          </label>
        </div>
        <div className="formGroup">
          <label className="formGroupLabel">
            <input
              type="text"
              className="textInput"
              id="lastName"
              name="lastName"
              value={formik.values.lastName}
              placeholder="姓"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("lastName", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <span className="errorText">{formik.errors.lastName}</span>
            )}
          </label>
        </div>
        <div className="formGroup">
          <label className="formGroupLabel">
            <input
              type="text"
              className="textInput"
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              placeholder="名"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("firstName", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <span className="errorText">{formik.errors.firstName}</span>
            )}
          </label>
        </div>
        <div className="formGroup">
          <label className="formGroupLabel">
            <input
              className="textInput"
              type="email"
              name="email"
              value={formik.values.email}
              placeholder="メールアドレス"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("email", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && <span className="errorText">{formik.errors.email}</span>}
          </label>
        </div>
        <div className="formGroup">
          <label className="formGroupLabel">
            <input
              className="textInput"
              type="tel"
              id="phone"
              name="phone"
              value={formik.values.phone}
              placeholder="電話番号"
              onChange={(e: FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                formik.setFieldValue("phone", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone && formik.errors.phone && <span className="errorText">{formik.errors.phone}</span>}
          </label>
        </div>
        <div className="formGroup">
          <label className="label">従業員数</label>
          <div className="selectContainer">
            <select
              className={`select ${formik.values.numberOfEmployees === "" && `selectEmpty`}`}
              id="numberOfEmployees"
              name="numberOfEmployees"
              value={formik.values.numberOfEmployees}
              onChange={(e: FormEvent<HTMLSelectElement>) => {
                e.preventDefault();
                formik.setFieldValue("numberOfEmployees", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            >
              <option value="">選択...</option>
              {numbersOfEmployees.map((numberOfEmployees) => (
                <option key={numberOfEmployees} value={numberOfEmployees}>
                  {numberOfEmployees}
                </option>
              ))}
            </select>
            <ExpandMoreIcon style={{ fontSize: 22, color: colors.black }} />
          </div>
          {formik.touched.numberOfEmployees && formik.errors.numberOfEmployees && (
            <span className="errorText">{formik.errors.numberOfEmployees}</span>
          )}
        </div>
        <div className="formGroup">
          <label className="label">年間採用人数</label>
          <div className="selectContainer">
            <select
              className={`select ${formik.values.numberOfHiredAnnually === "" && `selectEmpty`}`}
              id="numberOfHiredAnnually"
              name="numberOfHiredAnnually"
              value={formik.values.numberOfHiredAnnually}
              onChange={(e: FormEvent<HTMLSelectElement>) => {
                e.preventDefault();
                formik.setFieldValue("numberOfHiredAnnually", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            >
              <option value="">選択...</option>
              {numbersOfHiredAnnually.map((numberOfHiredAnnually) => (
                <option key={numberOfHiredAnnually} value={numberOfHiredAnnually}>
                  {numberOfHiredAnnually}
                </option>
              ))}
            </select>
            <ExpandMoreIcon style={{ fontSize: 22, color: colors.black }} />
          </div>
          {formik.touched.numberOfHiredAnnually && formik.errors.numberOfHiredAnnually && (
            <span className="errorText">{formik.errors.numberOfHiredAnnually}</span>
          )}
        </div>
        <div className="formGroup">
          <label className="label">現在の採用媒体比率</label>
          <div className="selectContainer">
            <select
              className={`select ${formik.values.ratioOfRecruitmentAgency === "" && `selectEmpty`}`}
              id="ratioOfRecruitmentAgency"
              name="ratioOfRecruitmentAgency"
              value={formik.values.ratioOfRecruitmentAgency}
              onChange={(e: FormEvent<HTMLSelectElement>) => {
                e.preventDefault();
                formik.setFieldValue("ratioOfRecruitmentAgency", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            >
              <option value="">選択...</option>
              {ratiosOfRecruitmentAgency.map((ratioOfRecruitmentAgency) => (
                <option key={ratioOfRecruitmentAgency} value={ratioOfRecruitmentAgency}>
                  {ratioOfRecruitmentAgency}
                </option>
              ))}
            </select>
            <ExpandMoreIcon style={{ fontSize: 22, color: colors.black }} />
          </div>
          {formik.touched.ratioOfRecruitmentAgency && formik.errors.ratioOfRecruitmentAgency && (
            <span className="errorText">{formik.errors.ratioOfRecruitmentAgency}</span>
          )}
        </div>
        <div className="formGroup">
          <label className="label">最も注力したい採用形態</label>
          <div className="selectContainer">
            <select
              className={`select ${formik.values.preferredRecruitmentType === "" && `selectEmpty`}`}
              id="preferredRecruitmentType"
              name="preferredRecruitmentType"
              value={formik.values.preferredRecruitmentType}
              onChange={(e: FormEvent<HTMLSelectElement>) => {
                e.preventDefault();
                formik.setFieldValue("preferredRecruitmentType", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            >
              <option value="">選択...</option>
              {preferredRecruitmentTypes.map((preferredRecruitmentType) => (
                <option key={preferredRecruitmentType} value={preferredRecruitmentType}>
                  {preferredRecruitmentType}
                </option>
              ))}
            </select>
            <ExpandMoreIcon style={{ fontSize: 22, color: colors.black }} />
          </div>
          {formik.touched.preferredRecruitmentType && formik.errors.preferredRecruitmentType && (
            <span className="errorText">{formik.errors.preferredRecruitmentType}</span>
          )}
        </div>
        <div className="formGroup">
          <label className="label">現在のステータス</label>
          <div className="selectContainer">
            <select
              className={`select ${formik.values.currentStatus === "" && `selectEmpty`}`}
              id="currentStatus"
              name="currentStatus"
              value={formik.values.currentStatus}
              onChange={(e: FormEvent<HTMLSelectElement>) => {
                e.preventDefault();
                formik.setFieldValue("currentStatus", e.currentTarget.value, true);
              }}
              onBlur={formik.handleBlur}
            >
              <option value="">選択...</option>
              {currentStatuses.map((currentStatus) => (
                <option key={currentStatus} value={currentStatus}>
                  {currentStatus}
                </option>
              ))}
            </select>
            <ExpandMoreIcon style={{ fontSize: 22, color: colors.black }} />
          </div>
          {formik.touched.currentStatus && formik.errors.currentStatus && (
            <span className="errorText">{formik.errors.currentStatus}</span>
          )}
        </div>
        <div className="formGroup">
          <div className="checkList">
            <div className="checkListItem">
              <input
                className="checkboxInput"
                type="checkbox"
                id="permissionPrivacyPolicy"
                checked={formik.values.permissionPrivacyPolicy}
                onChange={() => {
                  const newValue = !formik.values.permissionPrivacyPolicy;
                  formik.setFieldValue("permissionPrivacyPolicy", newValue, true);
                }}
              />
              <label className="checkboxLabel" htmlFor="permissionPrivacyPolicy">
                <span
                  className={`checkboxLabelIcon ${formik.values.permissionPrivacyPolicy && `checkboxLabelIconChecked`}`}
                />
                <span className="checkboxLabelText">
                  <a className="checkboxLabelTextLink" href={PRIVACY_POLICY_URL} target="_blank">
                    プライバシーポリシー
                  </a>
                  及び
                  <a className="checkboxLabelTextLink" href={PRIVACY_POLICY_MARKETING_URL} target="_blank">
                    個人情報のお取り扱い
                  </a>
                  に同意する
                </span>
              </label>
            </div>
          </div>
          {formik.touched.permissionPrivacyPolicy && formik.errors.permissionPrivacyPolicy && (
            <span className="errorText">{formik.errors.permissionPrivacyPolicy}</span>
          )}
        </div>
        <SubmitButton disabled={!formik.dirty || !formik.isValid || submitting} />
      </form>
    </>
  );
};
